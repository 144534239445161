<template>
  <div id="page-wrap">
    <div class="hello">
      <img src="@/assets/jg.svg" crossorigin="" />
    </div>
    <div id="canvas"></div>
    <div id="content">
      <div id="planes">
        <div class="plane-wrapper">
          <div class="plane-inner">
            <div class="plane">
              <img
                src="@/assets/6.png"
                crossorigin=""
                data-sampler="planeTexture"
              />
            </div>
          </div>
        </div>
        <div class="plane-wrapper">
          <div class="plane-inner">
            <div class="plane">
              <img
                src="@/assets/5.png"
                crossorigin=""
                data-sampler="planeTexture"
              />
            </div>
          </div>
        </div>
        <div class="plane-wrapper">
          <div class="plane-inner">
            <div class="plane">
              <img
                src="@/assets/1.png"
                crossorigin=""
                data-sampler="planeTexture"
              />
            </div>
          </div>
        </div>
        <div class="plane-wrapper">
          <div class="plane-inner">
            <div class="plane">
              <img
                src="@/assets/2.png"
                crossorigin=""
                data-sampler="planeTexture"
              />
            </div>
          </div>
        </div>

        <div class="plane-wrapper">
          <div class="plane-inner">
            <div class="small-plane">
              <img
                src="@/assets/7.png"
                crossorigin=""
                data-sampler="planeTexture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Curtains, RenderTarget, Plane, ShaderPass } from "curtainsjs";
import { onMounted } from "vue";

export default {
  name: "jg-gallery",
  props: {
    msg: String
  },
  setup() {
    onMounted(() => {
      window.addEventListener("load", () => {
        // we will keep track of all our planes in an array
        let scrollEffect = 0;

        // set up our WebGL context and append the canvas to our wrapper
        const curtains = new Curtains({
          container: "canvas",
          antialias: true, // render targets will disable default antialiasing anyway
          pixelRatio: Math.min(1.5, window.devicePixelRatio) // limit pixel ratio for performance
        });

        curtains
          .onRender(() => {
            // update our planes deformation
            // increase/decrease the effect
            scrollEffect = curtains.lerp(scrollEffect, 0, 0.05);
          })
          .onScroll(() => {
            // get scroll deltas to apply the effect on scroll
            const delta = curtains.getScrollDeltas();

            // invert value for the effect
            delta.y = -delta.y;

            // threshold
            if (delta.y > 100) {
              delta.y = 100;
            } else if (delta.y < -100) {
              delta.y = -100;
            }

            if (Math.abs(delta.y) > Math.abs(scrollEffect)) {
              scrollEffect = curtains.lerp(scrollEffect, delta.y, 0.5);
            }
          })
          .onError(() => {
            // we will add a class to the document body to display original images
            document.body.classList.add("no-curtains");
          })
          .onContextLost(() => {
            // on context lost, try to restore the context
            curtains.restoreContext();
          });

        // get our planes elements
        const planeElements = document.getElementsByClassName("plane");
        const smallPlaneElements = document.getElementsByClassName(
          "small-plane"
        );

        const distortionTarget = new RenderTarget(curtains);
        const rgbTarget = new RenderTarget(curtains);

        const vs = `
        precision mediump float;
    
        // default mandatory variables
        attribute vec3 aVertexPosition;
        attribute vec2 aTextureCoord;
    
        uniform mat4 uMVMatrix;
        uniform mat4 uPMatrix;
    
        uniform mat4 planeTextureMatrix;
    
        // custom variables
        varying vec3 vVertexPosition;
        varying vec2 vTextureMatrixCoord;
    
        void main() {
    
            vec3 vertexPosition = aVertexPosition;
    
            gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
    
            // varyings
            vVertexPosition = vertexPosition;
            vTextureMatrixCoord = (planeTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
        }
    `;

        const fs = `
        precision mediump float;
    
        varying vec3 vVertexPosition;
        varying vec2 vTextureMatrixCoord;
    
        uniform sampler2D planeTexture;
    
        void main() {
            // just display our texture
            gl_FragColor = texture2D(planeTexture, vTextureMatrixCoord);
        }
    `;

        // add our planes and handle them
        for (let i = 0; i < planeElements.length; i++) {
          const plane = new Plane(curtains, planeElements[i], {
            vertexShader: vs,
            fragmentShader: fs
          });

          plane.setRenderTarget(distortionTarget);
        }

        // add the small planes as well
        for (let i = 0; i < smallPlaneElements.length; i++) {
          const plane = new Plane(curtains, smallPlaneElements[i], {
            vertexShader: vs,
            fragmentShader: fs,
            texturesOptions: {
              // textures images will be reduced, use LINEAR_MIPMAP_NEAREST
              minFilter: curtains.gl.LINEAR_MIPMAP_NEAREST
            }
          });

          plane.setRenderTarget(rgbTarget);
        }

        const distortionFs = `
        precision mediump float;
    
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
    
        uniform sampler2D uRenderTexture;
    
        uniform float uScrollEffect;
    
        void main() {
            vec2 textureCoords = vTextureCoord;
            vec2 texCenter = vec2(0.5, 0.5);
    
            // distort around scene center
            textureCoords.y += cos((textureCoords.x - texCenter.x) * 3.141592) * uScrollEffect / 500.0;
    
            gl_FragColor = texture2D(uRenderTexture, textureCoords);
        }
    `;

        const distortionPass = new ShaderPass(curtains, {
          fragmentShader: distortionFs,
          renderTarget: distortionTarget,
          uniforms: {
            scrollEffect: {
              name: "uScrollEffect",
              type: "1f",
              value: 0
            }
          }
        });

        distortionPass.onRender(() => {
          // update the uniform
          distortionPass.uniforms.scrollEffect.value = scrollEffect;
        });

        const rgbFs = `
        precision mediump float;
    
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
    
        uniform sampler2D uRenderTexture;
    
        uniform float uScrollEffect;
    
        void main() {
            vec2 textureCoords = vTextureCoord;
    
            vec2 redTextCoords = vec2(vTextureCoord.x, vTextureCoord.y - uScrollEffect / 300.0);
            vec2 greenTextCoords = vec2(vTextureCoord.x, vTextureCoord.y - uScrollEffect / 600.0);
            vec2 blueTextCoords = vec2(vTextureCoord.x, vTextureCoord.y - uScrollEffect / 900.0);
    
            vec4 red = texture2D(uRenderTexture, redTextCoords);
            vec4 green = texture2D(uRenderTexture, greenTextCoords);
            vec4 blue = texture2D(uRenderTexture, blueTextCoords);
    
            vec4 finalColor = vec4(red.r, green.g, blue.b, min(1.0, red.a + blue.a + green.a));
            gl_FragColor = finalColor;
        }
    `;

        const rgbPass = new ShaderPass(curtains, {
          fragmentShader: rgbFs,
          renderTarget: rgbTarget,
          uniforms: {
            scrollEffect: {
              name: "uScrollEffect",
              type: "1f",
              value: 0
            }
          }
        });

        rgbPass.onRender(() => {
          // update the uniform
          rgbPass.uniforms.scrollEffect.value = scrollEffect;
        });

        const blurFs = `
        precision mediump float;
    
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
    
        uniform sampler2D uRenderTexture;
    
        uniform float uScrollEffect;
        uniform vec2 uResolution;
    
    
        // taken from https://github.com/Jam3/glsl-fast-gaussian-blur
        vec4 blur5(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
            vec4 color = vec4(0.0);
            vec2 off1 = vec2(1.3333333333333333) * direction;
            color += texture2D(image, uv) * 0.29411764705882354;
            color += texture2D(image, uv + (off1 / resolution)) * 0.35294117647058826;
            color += texture2D(image, uv - (off1 / resolution)) * 0.35294117647058826;
            return color;
        }
    
        void main() {
            vec4 original = texture2D(uRenderTexture, vTextureCoord);
            vec4 blur = blur5(uRenderTexture, vTextureCoord, uResolution, vec2(0.0, 1.0));
    
            gl_FragColor = mix(original, blur, min(1.0, abs(uScrollEffect) / 5.0));
        }
    `;

        let curtainsBBox = curtains.getBoundingRect();

        const blurPass = new ShaderPass(curtains, {
          fragmentShader: blurFs,
          uniforms: {
            scrollEffect: {
              name: "uScrollEffect",
              type: "1f",
              value: 0
            },
            resolution: {
              name: "uResolution",
              type: "2f",
              value: [curtainsBBox.width, curtainsBBox.height]
            }
          }
        });

        blurPass
          .onRender(() => {
            // update the uniform
            blurPass.uniforms.scrollEffect.value = scrollEffect;
          })
          .onAfterResize(() => {
            curtainsBBox = curtains.getBoundingRect();
            blurPass.uniforms.resolution.value = [
              curtainsBBox.width,
              curtainsBBox.height
            ];
          });
      });
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@media screen {
  html,
  body {
    min-height: 100%;
  }

  body {
    margin: 0;
    font-size: 18px;

    background: #000;
    line-height: 1.4;
  }

  /*** layout ***/

  .flex-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  #page-wrap {
    position: relative;
  }

  /*** canvas ***/

  /* our canvas will be a bit taller than our window to avoid "glitched" top and bottom edges */
  #canvas {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
  }

  #planes {
    overflow: hidden;
  }

  .plane-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* margin-bottom: 15vh; */
    /* height: 100vh; */
    /* margin: 10vh 10% 10vh 0; */
  }

  .plane {
    /* position: absolute; */
    top: 0;
    display: flex;
    /* bottom: 17.5vh; */
    /* left: auto;
    right: auto; */
    max-width: 800px;
  }
  .plane-inner {
    height: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
  }

  /* .plane-wrapper:nth-child(even) .plane {
    right: 0;
    left: 15%;
  } */

  .small-plane {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 30vh;

    width: 60%;
    height: 60vh;
  }

  /* .plane-wrapper:nth-child(even) .small-plane {
    right: auto;
    left: 0;
  } */

  .plane img,
  .small-plane img {
    display: none;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /*** handling errors ***/

  .no-curtains .plane-title {
    z-index: 1;
  }

  .no-curtains .plane,
  .no-curtains .small-plane {
    display: flex;
    /* overflow: hidden; */
  }

  .no-curtains .plane img,
  .no-curtains .small-plane img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
}

.hello {
  width: 100%;
  height: 50px;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: -50px;
}
</style>
